import vars from '../_vars'
import { trackYM, trackEvent } from '../components/analytics';

export const closeChats = () => {
  vars.openChat.attr('data-visible', true);
  $('.dialog__chat').addClass('invisible');
  $('.section-dialog').attr('data-visible-chat', 'closed');
  trackYM('Body-Click-Close-Dialogue');
  trackEvent('BodyClickClose-Dialogue','Body','Click', 'Close-Dialogue');
}
