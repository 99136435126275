const trackYM = (eventBody) => {
  if (window.Ya && (window.Ya.Metrika || window.Ya.Metrika2)) ym('98600751', 'reachGoal', eventBody);
  console.log(eventBody);
}

const trackEvent = (eventName, eventCategory, eventAction, eventLabel, eventContext) => {
  if (typeof dataLayer !== 'undefined') {
    dataLayer.push({
      event: 'GAEvent',
      eventName: eventName,
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventContext: eventContext,
    });
  }
}

$(document).on('click', '.header__logo', function() {
  trackYM('Header-Click-Logo');
  trackEvent('HeaderClickLogo','Header','Click', 'Logo');
});

$(document).on('click', '.header__links-help', function() {
  trackYM('Header-Click-Help');
  trackEvent('HeaderClickHelp','Header','Click', 'Help');
});

$(document).on('click', '.menu-link-home', function() {
  trackYM('Footer-Click-Main');
  trackEvent('FooterClickMain','Footer','Click', 'Main');
});

$(document).on('click', '.menu-link-about', function() {
  trackYM('Footer-Click-About');
  trackEvent('FooterClickAbout','Footer','Click', 'About');
});

$(document).on('click', '.menu-link-store', function() {
  trackYM('Footer-Click-Store');
  trackEvent('FooterClickStore','Footer','Click', 'Store');
});

$(document).on('click', '.menu-link-contacts', function() {
  trackYM('Footer-Click-Contacts');
  trackEvent('FooterClickContacts','Footer','Click', 'Contacts');
});

$(document).on('click', '.menu-link-legal', function() {
  trackYM('Footer-Click-Legal');
  trackEvent('FooterClickLegal','Footer','Click', 'Legal');
});

$(document).on('click', '.buy-now-btn', function() {
  trackYM('Modal-Click-Book');
  trackEvent('ModalClickBook','Modal','Click', 'Book');
});

export { trackYM, trackEvent };