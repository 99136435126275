export default {
  BASE_URL: 'https://admin.dev.aiui.ru',

  windowEl: window,
  documentEl: document,
  htmlEl: document.documentElement,
  bodyEl: document.body,
  pageBody: $('.page__body'),
  header: $('#header'),
  footer: $('#footer'),
  collapseMenu: $('#menu'),
  chatsList: $('.dialog__chat-page-messages'),
  widgetWrapper: $('.dialog__chat-page-content-wrapper'),
  voiceTapSwitch: $('#voice-tap-switch'),
  openChat: $('.open-chat'),
  closeChat: $('.close-chat'),
  closeWidget: $('.close-widget'),
  helpTooltips: $('.help__tooltip'),
  interfaceIcon: $('.dialog__interface-indicator').find('svg'),
  recordingButton: $('.dialog__interface-button'),
  talkToAvatarButton: $('.dialog__chat-page').find('.btn-send'),
  audioPlayer: $('#audio-player'),
  toggleAudioBtn: $('.toggle-audio'),
}
