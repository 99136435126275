import vars from '../_vars'
import {openChats} from '../functions/openChats'
import {closeChats} from '../functions/closeChats'


/*const initToggleWidgets = () => {
  let type = vars.pageBody.data('name-widget');
  let currentWidget = $(`.dialog__chat-page-content-wrapper[data-id="${type}"]`);
  if(currentWidget.length) {
    vars.widgetWrapper.addClass('hidden');
    closeChats();
    setTimeout(() => {$(`.dialog__chat-page-content-wrapper[data-id="${type}"]`).removeClass('hidden')}, 200);
    setTimeout(openChats, 400);
  }
}

vars.closeWidget.on('click', () => {
  vars.widgetWrapper.addClass('hidden');
  closeChats();
  setTimeout(() => {$(`.dialog__chat-page-content-wrapper[data-id="#chat"]`).removeClass('hidden');}, 200);
  setTimeout(openChats, 400);
})

export default initToggleWidgets;*/
let prevWidget = null;
let getWidget = async (uid) => {
  if(!uid) return
  try {
    const response = await fetch('https://platform.bafsy.com/api/widgets/'+uid);
    const result = await response.json();
    initWidget(uid, result.html, result.css.replaceAll('%{widget_uid}', uid));
  } catch (error) {
    console.log(error);
  }
}

const initWidget = (uid, html, css) => {
  if(prevWidget == null) $('.dialog__chat-page-contents-wrapper').removeClass('hidden');
  var myCss = $('<style>'+css+'</style>');
  var myHtml = $(html);
  var myUid = uid;
  myHtml.prepend(myCss);
  myHtml.addClass('widget hidden');
  $('.widget').addClass('hidden');
  $('.dialog__chat-page-contents-wrapper').prepend(myHtml);
  $('.dialog__chat-page-content-wrapper[data-id="#chat"]').addClass('hidden');
  addParamsToWidget($('.widget#widget-' + uid), vars.widgetParametrs);

  //closeChats();
  setTimeout(function() {$('.widget#widget-'+uid).removeClass('hidden');}, 200);
  setTimeout(openChats, 400);

  var closeBtn = $('.widget#widget-'+uid).find('.close-widget');
  closeBtn.data('prev-widget', prevWidget);
  closeBtn.on('click', function(e) {
    $(e.currentTarget).parents().find('.widget#widget-'+uid).addClass('hidden');
    // Закрытие чата перед анимацией
    //closeChats();
    $('.dialog__chat-page-contents-wrapper').html('');
    // Анимация
    if($(e.currentTarget).data('prev-widget') == null || $(e.currentTarget).data('prev-widget') == myUid) {
      setTimeout(function() {
        $('.dialog__chat-page-content-wrapper[data-id="#chat"]').removeClass('hidden');
        $('.dialog__chat-page-contents-wrapper').addClass('hidden');
      }, 10);
    } else {
      setTimeout(function() {$('.widget#widget-'+$(e.currentTarget).data('prev-widget')).removeClass('hidden');}, 200);
    }
    // Открытие чата после анимации
    setTimeout(openChats, 400);
  });
}

function addParamsToWidget(container, params) {
  if (params === null || params === undefined) return;
  var keyParams = Object.keys(params) || [];
  if (keyParams.length === 0) return;

  var widgetContainer = container.find('.dialog__chat-page-body-widget');
  
  
  for (var index = 0; index < keyParams.length; index++) {
    //if (params[keyParams[index]] === '' || params[keyParams[index]] === null || params[keyParams[index]] === undefined) continue;

    var elements = widgetContainer.find('[data-field="' + keyParams[index] + '"]');

    if (elements.length > 0) {
      if (params[keyParams[index]] === undefined || params[keyParams[index]] === null || !params[keyParams[index]]) {
        if (elements.hasClass('card-text')) {
          var parent = elements.parent();
          parent.remove()
        }
        continue; 
      }  
    }

    if (elements.length > 0) {
      if (elements.prop("tagName") == 'DIV' || elements.prop("tagName") == 'SPAN') elements.text(params[keyParams[index]]);
      if (elements.prop("tagName") == 'IMG') elements.attr("src", params[keyParams[index]]);
      if (elements.prop("tagName") == 'A') {
        elements.attr("href", params[keyParams[index]]);
        elements.text(params[keyParams[index]]);
      }
      continue;
    }
    
    elements = widgetContainer.find('[data-field="template-card-field"]');

    if (elements.length > 0) {
      var currentElement = $(elements[0]).clone();
      var keyClass = '.' + $(currentElement).data('key-class');
      var valueClass = '.' + $(currentElement).data('value-class');

      $(currentElement).attr('data-field', keyParams[index]);
      $(currentElement).find(keyClass).text(keyParams[index].charAt(0).toUpperCase() + keyParams[index].slice(1) + ':');
      $(currentElement).find(valueClass).text(params[keyParams[index]]);
      $(currentElement).removeClass('hidden');
      $(elements).parent().append(currentElement);

      continue;
    }
     

    if (keyParams[index] == 'arrayImages') {
      elements = widgetContainer.find('[data-field="template-array-image"]');

      if (elements.length > 0) {
        params[keyParams[index]].forEach(function(imageLink, i) {
          if (imageLink !== '' && imageLink !== undefined && imageLink !== null) {
            var currentElement = $(elements[0]).clone();

            $(currentElement).attr('data-field', 'image' + i);
            $(currentElement).find('img').attr("src", imageLink);
            $(currentElement).removeClass('hidden');
            elements.parent().append(currentElement);
          }

          if (params[keyParams[index]].length - 1 === i) elements.remove();
        });

        continue;
      }
    }
  }
}

const initToggleWidgets = (uid) => {
  if (uid !== null) getWidget(uid);
  vars.pageBody.data('name-widget', null)
}

export default initToggleWidgets;


