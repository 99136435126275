import './_vendor';
import './_components';

function generateSessionId() {
  const currentDate = new Date().toISOString().replace(/[-:T.Z]/g, '');
  const randomPart = Math.floor(100000 + Math.random() * 900000).toString();
  return `${currentDate}${randomPart}`;
}
function updateSendCount() {
  const lastUpdate = localStorage.getItem('lastUpdate');
  const currentDate = new Date().toISOString().split('T')[0];

  if (lastUpdate !== currentDate) {
    localStorage.setItem('sendCount', 0);
    localStorage.setItem('lastUpdate', currentDate);
  }
}

$(window).on('load', () => {
  let sessionId = localStorage.getItem('sessionid');

  if (!sessionId) {
    sessionId = generateSessionId();
    localStorage.setItem('sessionid', sessionId);
  }
  updateSendCount();
  $('.page__body').attr('data-current-user-id', sessionId);
  (function(w,d,s,n,a){
    if(!w[n]){
      var l='call,catch,on,once,set,then,track,openCheckout'
      .split(','),i,o=function(n){
        return 'function'==typeof n ? o.l.push([arguments])&&o : function(){
          return o.l.push([n,arguments])&&o
        }
      },t=d.getElementsByTagName(s)[0],
      j=d.createElement(s);
      j.async=!0;
      j.src='https://cdn.fundraiseup.com/widget/'+a+'';
      t.parentNode.insertBefore(j,t);
      o.s=Date.now();
      o.v=5;
      o.h=w.location.href;
      o.l=[];
      for(i=0;i<8;i++) o[l[i]]=o(l[i]);
      w[n]=o
    }
  })(window,document,'script','FundraiseUp','AETTLAHR');
  setTimeout(() => {
    $('.section-dialog').css({ opacity: 1 });
  }, 150);
});
