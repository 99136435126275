import MicRecorder from 'mic-recorder-to-mp3';
import vars from '../_vars'
import handlingAiResponse from '../functions/handlingAiResponse'
import initToggleWidgets from '../components/toggleWidgets'
import { trackYM, trackEvent } from '../components/analytics';

$(document).ready(() => {
  const matchPlatform = () => {
    let platform = navigator.platform.toLowerCase();
    switch (true) {
      case /win/.test(platform):
        return `Windows`;
      case (/ipad|iphone|ipod/.test(platform) || (platform === 'macintel' && navigator.maxTouchPoints > 1)):
        return 'IOS';
      case /mac/.test(platform):
        return 'macOS';
    }
  };

  const recordingIconWrapper = vars.recordingButton.find('.dialog__interface-button-icon-wrapper');
  const recordingTimer = recordingIconWrapper.find('#timer');
  const recordingTimerSecs = recordingTimer.find('.sec');
  const recordingTimerMins = recordingTimer.find('.min');
  const conversations = $('.dialog__chat-page-messages');
  let remainingTime = 20;
  let maxLengthAudio = remainingTime;
  let minLengthAudio = 2;

  let recorder;
  let timer;
  let chunks = [];
  let countdownTimer = null;

  let indexWaiting = 0;
  let intervalWaiting = null;


  const calcTimer = (secs) => {
    if(secs) {
      let min = Math.floor(secs / 60);
      let sec = secs - (min * 60);
      return {
        min: min < 10 ? `0${min}` : `${min}`,
        sec: sec < 10 ? `0${sec}` : `${sec}`
      }
    } else {
      console.log('Установите время.')
    }
  };
  const initTimer = () => {
    timer = calcTimer(remainingTime);
    recordingTimerMins.html(timer.min);
    recordingTimerSecs.html(timer.sec);

    if(!countdownTimer) {
      countdownTimer = setInterval(() => {
        remainingTime--
        let min = Number(recordingTimerMins.html());
        let sec = Number(recordingTimerSecs.html());
        if(min > 0 || sec > 0) {
          if(sec <= 0) {
            min--
            sec = 59
          }
          else {
            sec--
          }
          recordingTimerMins.html(min < 10 ? `0${min}` : `${min}`);
          recordingTimerSecs.html(sec < 10 ? `0${sec}` : `${sec}`);
        } else {
          clearInterval(countdownTimer);
          stopRecording();
        }
      }, 1000);
    }
  }

  if(matchPlatform() == 'IOS' || matchPlatform() == 'macOS') {
    sessionStorage.setItem("firstClick", "false");
    vars.audioPlayer.on('click', () => {
      vars.audioPlayer[0].volume=0;
      vars.audioPlayer[0].currentTime = 0;
      vars.audioPlayer[0].play();
      sessionStorage.setItem("firstClick", "true");
    })
  }

  // Function to start recording audio
  vars.recordingButton.on('mousedown touchstart', async (e) => {
    e.preventDefault();
    resetRecording();
    try {
      recordingIconWrapper.addClass('show-timer');
      recordingIconWrapper.removeClass('show-icon');
      initTimer();
      if(matchPlatform() != 'IOS' && matchPlatform() != 'macOS') {
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            recorder = new MediaRecorder(stream);
            chunks = [];
            recorder.start();
            recorder.ondataavailable = (e) => {
              chunks.push(e.data);
            };
          });
      } else {
        recorder = new MicRecorder({bitRate: 128});
        recorder.start();
      }
      trackYM('Body-Touch-Voice');
      trackEvent('BodyTouchVoice','Body','Touch', 'Voice');
    } catch (error) {
      console.error('Error starting recording:', error);
      console.log('Ошибка при начале записи.')
    }
  });
  // Function to stop recording audio
  vars.recordingButton.on('mouseup touchend', async (e) => {
    e.preventDefault();
    if(matchPlatform() == 'IOS' || matchPlatform() == 'macOS') {
      if(sessionStorage.getItem('firstClick') != 'true') vars.audioPlayer.click();
    }

    if(remainingTime <= maxLengthAudio - minLengthAudio) {
      clearInterval(countdownTimer);
      countdownTimer = null;
      stopRecording();
    } else {
      console.log(`Минимальное время записи: ${minLengthAudio} сек.`)
      setTimeout(() => {
        clearInterval(countdownTimer);
        countdownTimer = null;
      }, 500)
    }
    resetRecording();
  });

  const resetRecording = () => {
    remainingTime = maxLengthAudio;
    timer = calcTimer(remainingTime);
    recordingTimerMins.html(timer.min);
    recordingTimerSecs.html(timer.sec);
    recordingIconWrapper.removeClass('show-timer');
    recordingIconWrapper.addClass('show-icon');
  }

  const stopRecording = () => {
    clearInterval(countdownTimer);
    vars.recordingButton.addClass('locked');
    vars.talkToAvatarButton.addClass('locked');

    try {
      if(matchPlatform() != 'IOS' && matchPlatform() != 'macOS') {
        recorder.stop();
        setTimeout(() => {
          const newBlob = new Blob(chunks, { type: 'audio/mp4' });
          uploadAudio(newBlob);
        }, 100);
      } else {
        recorder
          .stop()
          .getMp3()
          .then(([buffer, blob]) => {
            const newBlob = new File(buffer, 'audio.mp3', {
              type: blob.type,
              lastModified: Date.now()
            });
            uploadAudio(newBlob);
          })
          .catch((e) => {console.error(e)});
      }


    } catch (error) {
      console.error('Error stopping recording:', error);
      console.log('Ошибка при завершении записи.')
    }
  }
  // Function to send text prompt to the avatar
  $(document).on('keyup', e => {
    if( e.code === 'Enter' ) vars.talkToAvatarButton.click();
  });
  vars.talkToAvatarButton.on('click', async (e) => {
    e.preventDefault();

    const currentParent = $(e.currentTarget).closest('.dialog__chat-page');
    const prompt = currentParent.find('.input-send').val().trim();
    if (!prompt || $(e.currentTarget).hasClass('locked')) return;

    vars.recordingButton.addClass('locked');
    vars.talkToAvatarButton.addClass('locked');

    displayMessage('user', prompt);
    vars.interfaceIcon.attr('class', 'cloud preanimate');
    var sendCount = parseInt(localStorage.getItem('sendCount')) || 0;
    localStorage.setItem('sendCount', sendCount + 1);
    currentParent.find('.input-send').val('');
    setTimeout(() => {
      vars.interfaceIcon.removeClass('preanimate');
    }, 300);

    intervalWaiting = setInterval(() => {
      indexWaiting++;
      if(indexWaiting == 4) {
        $('#textWaiting').attr('data-visible', 'true');
      }
    }, 1000);

    try {
      const response = await sendTextPrompt(prompt);

      // Ассистент НЕ отвечает голосом во время работы с чатом
      if(response) {
        let content = handlingAiResponse(response.text);
        let html = content.html;
        clearInterval(intervalWaiting);
        indexWaiting = 0;
        $('#textWaiting').attr('data-visible', 'false');
        vars.interfaceIcon.attr('class', 'circle');
        vars.recordingButton.removeClass('locked');
        vars.talkToAvatarButton.removeClass('locked');
        //displayMessage('assistant', html);
        //initToggleWidgets();
        processingResponse(response.text, true)
        addDonationMessage()
      }
      // Ассистент отвечает голосом во время работы с чатом
      // if (response) {
      //   await handleAudioStreaming(response.text);
      // }

    } catch (error) {
      console.error('Error sending text prompt:', error);
      console.log('Ошибка при отправке запроса.')
    }
    trackYM('Body-Send-Message');
    trackEvent('BodySendMessage','Body','Send', 'Message');
  });

  function processingResponse(currentResponse, isText) {
    var inputText = currentResponse.trim();
    var matches = [];
    var regex = /\$&(.*?)\$&/g; 
    var match;
    var uidWidget = null;
    var textForSpeech = '';
    var hasParams = false;

    while ((match = regex.exec(inputText)) !== null) matches.push(match[1]);

    if (matches.length > 0) {
      var stringParams = matches[0];
      var textWithoutParams = inputText.replace(/\$&.*?\$&\s*/, '').trim();
      var propertiesArray = stringParams.match(/{{(.*?)}}/g) || [];
      var resultData = {};

      propertiesArray.forEach(function(item) {
        var content = item.slice(2, -2); 
        var parts = content.split(/:(.+)/);
        var key = (parts[0] ? parts[0].trim() : '').toLowerCase();
        var value = parts[1] ? parts[1].trim() : '';
        
        if (parts.length === 1) key = key.slice(0, -1);

        if (/^(img)\d+$/.test(key)) {
          if (resultData['arrayImages'] === undefined) resultData['arrayImages'] = [];
          resultData['arrayImages'].push(value);
        } else {
          var formattedKey = key === 'name' ? 'title' : key;
          resultData[formattedKey] = value;
        }
      });

      var uidMatch = stringParams.match(/(uid[a-z0-9]+)/);
      uidWidget = uidMatch ? uidMatch[1] : null;
      textForSpeech = textWithoutParams;
      hasParams = true;
      vars.widgetParametrs = resultData;
    } else {
      var uidMatchAtStart = inputText.match(/#(uid[a-z0-9]+)/);
      uidWidget = uidMatchAtStart ? uidMatchAtStart[1] : null;
      if (uidWidget) textForSpeech = inputText.replace(/#(uid[a-z0-9]+)/, '').trim();
    }

    if (uidWidget) initToggleWidgets(uidWidget);
    if (hasParams || uidWidget) {
      if (isText) {
        displayMessage('assistant', textForSpeech);
      } else {
        handleAudioStreaming(textForSpeech);
      }
    } else {
      console.log("Нет переменной, заключенной в $& и UID.");
      if (isText) {
        displayMessage('assistant', currentResponse);
      } else {
        handleAudioStreaming(currentResponse);
      }
    }
  }
  // Function to upload recorded audio
  const uploadAudio = async (blob) => {
    vars.interfaceIcon.attr('class', 'cloud preanimate');
    setTimeout(() => {
      vars.interfaceIcon.removeClass('preanimate');
    }, 300);

    intervalWaiting = setInterval(() => {
      indexWaiting++;
      if(indexWaiting == 4) {
        $('#textWaiting').attr('data-visible', 'true');
      }
    }, 1000);

    try {
      const formData = new FormData();
      formData.append('file', blob, `audio.${blob.type.slice(blob.type.indexOf("/") + 1)}`);

      const response = await fetch(`${vars.BASE_URL}/engine/voice_talk/z1ejte/12/14/${vars.pageBody.data('current-user-id')}`, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      //handleAudioStreaming(result.response);
      displayMessage('user', result.transcription);
      var sendCount = parseInt(localStorage.getItem('sendCount')) || 0;
      localStorage.setItem('sendCount', sendCount + 1);
      processingResponse(result.response, false)
    } catch (error) {
      console.error('Error uploading audio:', error);
      console.log(`Ошибка при загрузке аудио: ${error.message}`)
    }
  }

  const sendTextPrompt = async (prompt) => {
    try {
      const response = await fetch(`${vars.BASE_URL}/engine/talk/z1ejte/12/14/${vars.pageBody.data('current-user-id')}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'text/plain'
          },
          body: prompt
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error sending text prompt:', error);
      console.log('Ошибка при отправке запроса.')
      throw error;
    }
  }

  // Function to display messages in the conversation
  function displayMessage(role, message) {
    for(let conversation of conversations) {
      let messageElement = $('<li class="dialog__chat-page-message hidden"></li>');
      let messageContent = $('<div class="dialog__chat-page-message-content"></div>');

      messageElement.append(messageContent)
      messageElement.addClass(role === 'user' ? 'message-user' : 'message-assistant')
      messageContent.html(message);
      $(conversation).append(messageElement);

      setTimeout(() => {messageElement.removeClass('hidden')}, 200)

      let messages = $(conversation).find('.dialog__chat-page-message');
      setTimeout(() => {
        let offsetTopLastMessage = messages[messages.length - 1].offsetTop - vars.header[0].offsetHeight;
        $(conversation).animate({
          scrollTop: offsetTopLastMessage
        }, 200);
      }, 200)

    }
  }
  function addDonationMessage() {
    var sendCount = parseInt(localStorage.getItem('sendCount')) || 0;
    var notifycationDonationHtml = '<div style="display: inline-block; margin-right: 20px;">' +
                                    '<span>Oops, didn’t mean to interrupt! 	&#128522;<br>Just a quick note: you’ve asked 20 questions so far, and our daily free limit is 25, so you have 5 more today.<br>If you’re finding this guide helpful and want to keep the questions coming, a small $3 donation unlocks unlimited access for the whole month.<br>This helps keep your AI-Guide running smoothly.</span>'+
                                    '</div>'+
                                    '<br>'+
                                    '<a id="donateButton" href="#" style="display: inline-block; background-color: #00a3bb; color: white; border-radius: 10px; padding: 10px 15px; font-size: 16px; margin-top: 10px; text-decoration: none; cursor: pointer; transition: background-color 0.3s, color 0.3s;" ' +
                                    'onmouseover="this.style.backgroundColor=\'white\'; this.style.color=\'black\';" ' +
                                    'onmouseout="this.style.backgroundColor=\'#00a3bb\'; this.style.color=\'white\';">Donate</a>';  
    var donationHTML = '<div style="display: inline-block; margin-right: 20px;">' +
        '<span>It looks like you’ve reached today’s limit of 25 free questions—awesome curiosity! &#11088; If you&#8217;d like to keep going, consider a small $5 donation to unlock unlimited questions for the entire month. Your support helps keep this AI-Guide running smoothly so I can continue offering insights whenever you need them. Let me know if you’d like to continue now, or feel free to check back in tomorrow!</span>' +
        '</div>';

    if (sendCount === 20) {
      displayMessage('assistant', notifycationDonationHtml);
      initDonationButton();
    }
    if (sendCount === 25) {
      displayMessage('assistant', donationHTML);
    }
  }
  function initDonationButton() {
    $(document).on('click', '#donateButton', function(event) {
      event.preventDefault();
      $('#donatModal').modal('show');
    });
  }
  const handleAudioStreaming = async (prompt) => {
    let content = handlingAiResponse(prompt);
    let html = content.html;
    let text = content.text;
    let isRussian = /[а-яА-ЯЁё]/.test(prompt);
    let speechKitUrl =''

    if (isRussian) {
      speechKitUrl = '/engine/voice_yandex_sk/z1ejte/12/14'
    } else {
      speechKitUrl = '/engine/voice/z1ejte/12/14'
    }


    try {
      const audioResponse = await fetch(`${vars.BASE_URL}${speechKitUrl}`, {
        method: 'POST',
        body: text
      });

      if (!audioResponse.ok) {
        throw new Error(`Failed to fetch audio response. Status: ${audioResponse.status}`);
      }

      const audioBlob = await audioResponse.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      clearInterval(intervalWaiting);
      indexWaiting = 0;
      $('#textWaiting').attr('data-visible', 'false');
      // Check if audioPlayer is defined before setting src
      if (vars.audioPlayer.length) {
        vars.toggleAudioBtn.attr('data-status', 'running');
        if(sessionStorage.getItem("typeChecked") != 'chat-tab') {
          vars.toggleAudioBtn.attr('data-visible', 'true');
          vars.recordingButton.attr('data-visible', 'false');
        } else {
          vars.toggleAudioBtn.attr('data-visible', 'false');
          vars.recordingButton.attr('data-visible', 'false');
        }
        vars.audioPlayer[0].currentTime = 0;
        vars.audioPlayer[0].volume = 1;
        vars.audioPlayer[0].src = audioUrl;
        vars.audioPlayer[0].play();
        vars.interfaceIcon.attr('class', 'equalizer');
        displayMessage('assistant', html);
        initToggleWidgets();
        vars.audioPlayer[0].onended = () => {
          vars.toggleAudioBtn.attr('data-status', 'stopped');
          vars.interfaceIcon.attr('class', 'circle');
          if(sessionStorage.getItem("typeChecked") != 'chat-tab') {
            vars.toggleAudioBtn.attr('data-visible', 'false');
            vars.recordingButton.attr('data-visible', 'true');
          } else {
            vars.toggleAudioBtn.attr('data-visible', 'false');
            vars.recordingButton.attr('data-visible', 'false');
          }
          vars.recordingButton.removeClass('locked');
          vars.talkToAvatarButton.removeClass('locked');
        }
        addDonationMessage()
      } else {
        throw new Error('Audio player is not defined.');
      }

    } catch (error) {
      console.error('Error handling audio streaming:', error);
    }
  }

  // Function to check initialization status
  const checkInitializationStatus = async () => {
    try {
      const response = await fetch(`${vars.BASE_URL}/initialization_status`);
      const data = await response.json();
      if (data.initialized) {
        console.log('Инициализация завершена');
      } else if (data.error) {
        console.log(`Ошибка инициализации: ${data.error}`)
      } else {
        console.log('Инициализация продолжается...')
      }
    } catch (error) {
      console.error('Error fetching initialization status:', error);
    }
  }

  // Check initialization status on page load
  checkInitializationStatus();
});