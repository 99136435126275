import vars from '../_vars'
import { trackYM, trackEvent } from '../components/analytics';

vars.toggleAudioBtn.on('click', (e) => {
  vars.toggleAudioBtn.attr('data-visible', 'false');
  vars.recordingButton.attr('data-visible', 'true');
  vars.recordingButton.removeClass('locked');
  vars.talkToAvatarButton.removeClass('locked');
  vars.interfaceIcon.attr('class', 'circle');
  vars.audioPlayer[0].pause();
  trackYM('Body-Pause-Voice');
  trackEvent('BodyPauseVoice','Body','Pause', 'Voice');
})